import { axios } from '@/utils/request'
// 我的订单
export const agreement = (msg, sucFn, errorFn) => {
  axios({
    method: 'post',
    url: '/app/Cms/agreement',
    data: msg
  })
    .then(function (res) {
      if (res.code === 1) {
        typeof sucFn === 'function' && sucFn(res.data)
      } else {
        typeof errorFn === 'function' && errorFn(res.msg)
      }
    })
    .catch(function (code, error) {
      typeof errorFn === 'function' && errorFn(code, error)
    })
}
