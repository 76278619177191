import axios from 'axios'
const service = axios.create({

  baseURL: process.env.API_ROOT,
  timeout: 5000000 // 请求超时时间
})

service.interceptors.request.use(config => {        
  return config
}, error => {
  
  Promise.reject(error)
})
service.interceptors.response.use(
  response => {
    if (response.data.code == 1) {
      return response.data
    } else {
      Toast(response.data.msg);
      return response.data
    }
  },
  error => {
    return Promise.reject(error)
  }
)
export {
  service as axios
}
