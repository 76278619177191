<template>
  <div class="">
    <header-com :index="1"></header-com>
    <div class="index_container">
       <div class="about_container">
           <div v-html="content"></div>
       </div>
    </div>
    <bottom-com></bottom-com>
  </div>
</template>

<script>
import '@/css/index.css'
// @ is an alias to /src
import headerCom from '@/components/header.vue'
import bottomCom from '@/components/bottom.vue'
import { agreement } from '@/api/index.js'

export default {
  name: 'Home',
  components: {
    headerCom,
    bottomCom
  },
  data() {
    return {
      content: '',
      content_en: ''
    };
  },
     watch:{
      // 监听路由发生改变
      '$route':{
        handler(newVal) {
          this.getData(newVal.query.id)
         }	
      }
    },
  mounted() {
     this.getData(this.$route.query.id)
  },
  methods: {
   getData(type) {
          agreement({agreement_type:type},(res) => {
           
            if ( localStorage.getItem('languageStorage')=='zh'){
               this.content = res.content
            } else{
               this.content = res.content_en
            }
          
            }, (err) => { console.log(err) })
      },
   },
}
</script>
<style scoped>
  .about_container{
    padding: 80px 260px;
    min-height: calc(100vh - 96px - 386px);
  }
</style>
