<template>
  <div class="header_container">
      <img src="@/assets/logo.png" @click="toUrl(0)" class="logo"/>
      <div class="header_nav flex">
        <div class="nav" :class="index==0?'active_nav':''" @click="toUrl(0)">{{ $t('header.menu1') }}</div>
        <div class="nav" :class="index==1?'active_nav':''" @click="toUrl(1)">{{ $t('header.menu2') }}</div>
        <div class="nav" :class="index==2?'active_nav':''" @click="toUrl(2)">{{ $t('header.menu3') }}</div>
        <div class="nav" @click="showCode=!showCode" v-click-outside="closeCode">
          {{ $t('header.menu4') }}
          <div class="app_code_box app_header_code center" v-show="showCode" >
             <img src="@/assets/code.png" class="img"/>
              <div class="text">
                {{ $t('code.title') }}<br/>{{ $t('code.subTitle') }}
              </div>
            </div>
        </div>
        <div class="change_lan align-center" @click="showChange=!showChange" v-click-outside="closeChange">
           <img src="@/assets/lan.png"/>
            <span>{{textLan}}</span>
            <img src="@/assets/arrow.png"/>
            <div class="change_box" v-show="showChange">
              <div @click="setLab(0)">简体中文</div>
               <div @click="setLab(1)">English</div>
            </div>
        </div>
      </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
export default {
  name: 'headerCom',
  props: {
    index: Number
  },
   directives: {
    ClickOutside
  },
  data () {
    return {
      showChange: false,
      showCode: false,
      textLan: localStorage.getItem('languageStorage')=='zh'?'简体中文':'English'
    }
  },
  methods: {
    toUrl(index){
      if (index==0){
         this.$router.push({name: 'home'})
      }else if (index==1){
            this.$router.push({path: 'about',query:{id:3}});
      }else if (index==2){
            this.$router.push({path: 'join',query:{id:5}});
      }
    },
    closeChange(){
      this.showChange = false
    },
    closeCode (){
      this.showCode = false
    },
    setLab (type){
      if (type==0){
              localStorage.setItem('languageStorage','zh')
              this.textLan = '简体中文'
      }else{
          localStorage.setItem('languageStorage','en')
           this.textLan = 'English'
      }
      location.reload()

    }
  }

}
</script>

<style scoped>
  .header_container{
    width: 1920px;
    height: 96px;
    background: #1E2126;
    padding: 0 260px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
  }
  .header_nav .nav{
    font-size: 18px;
    font-weight: 400;
    color: #FFFFFF;
     margin-left: 120px;
    cursor: pointer;
    position: relative;
  }
  .active_nav{
    position: relative;
    color: #FD5D7D !important;
  }
  .active_nav::after{
    content: '';
    width: 72px;
    height: 2px;
    background: #FD5D7D;
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .change_lan{
    margin-left: 120px;
      cursor: pointer;
      position: relative;
  }
  .change_lan span{
     font-size: 18px;
    font-weight: 400;
    color: #FFFFFF;
    padding: 0 4px 0 6px;
  }
  .change_box{
    width: 160px;
  height: 134px;
  background: #FFFFFF;
  box-shadow: 0px 0px 40px 0px rgba(0,0,0,0.08);
  border-radius: 10px;
  position: absolute;
  right: 0;
  top: 40px;
  padding-top: 24px;
  text-align: center;
  }

.header_container .logo{
  width: 180px;
  height: 40px;
  cursor: pointer;
}

.app_header_code{
    left: -60px !important;
    top: 40px !important;
  }
  .change_box div{
    font-size: 20px;
font-weight: 500;
color: rgba(0,0,0,0.5);
cursor: pointer;
  }
  .change_box div:nth-child(1){
    padding-bottom: 30px;
  }
</style>
