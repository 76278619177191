<template>
  <div>
    <div class="bottom_container">
      <div class="bottom_left">
         <img src="@/assets/logo_2.png" class="logo"/>
         <div class="bottom_contact align-center">
            <span>联系我们：</span>
             <img src="@/assets/c1.png"/>
              <img src="@/assets/c2.png"/>
               <img src="@/assets/c3.png"/>
         </div>
         <div class="bottom_contact">
           <span>Copyright@2004-2023<br/>上海贰零叁贰电子商务有限公司 版权所有</span>
         </div>
      </div>
      <div class="bottom_right flex">
          <div class="bottom_menu">
            <div>下载客户端</div>
            <span>web 版本</span>
             <span>Android 版本</span>
              <span>iOS 版本</span>
          </div>
           <div class="bottom_menu">
            <div>关于2032MALL</div>
            <span @click="toUrl(0)">关于我们</span>
             <span @click="toUrl(1)">加入我们</span>
              <span @click="toUrl(2)">服务条款</span>
              <span @click="toUrl(3)">隐私政策</span>
          </div>
           <div class="bottom_menu">
            <div>更换语言</div>
            <span class="align-center">简体中文 <img src="@/assets/arrow.png"/></span>
          </div>
      </div>
  </div>
  <div class="icp_bottom" @click="toIcp">沪ICP备2023009487号-1</div>
  </div>
</template>

<script>
export default {
  name: 'headerCom',
  props: {
    msg: String
  },
  methods: {
    toUrl(index){
      if (index==0){
          this.$router.push({path: 'about',query:{id:3}});
      }else if (index==1){
           this.$router.push({path: 'join',query:{id:5}});
      }else if (index==2){
            this.$router.push({path: 'join',query:{id:6}});
      }else if (index==3){
            this.$router.push({path: 'join',query:{id:2}});
      }
    },
    closeChange(){
      this.showChange = false
    },
    toIcp (){
      window.open('https://beian.miit.gov.cn/' ,'_blank')
    }
  }
}
</script>

<style scoped>
 .bottom_container{
  width: 1920px;
  height: 386px;
  background: #1E2126;
  padding: 70px 260px 0 260px;
  display: flex;
  justify-content: space-between;
 }

.icp_bottom{
   width: 1920px;
  height: 80px;
   background: #1E2126;
   text-align: center;
     font-size: 20px;
font-weight: 500;
color: #FFFFFF;
text-decoration: underline;
cursor: pointer;
}
.bottom_left .logo{
  width: 275px;
  height: 58px;
}
.bottom_contact{
  padding-top: 30px;
}
.bottom_contact span{
  font-size: 20px;
font-weight: 500;
color: #FFFFFF;
padding-right: 6px;
}
.bottom_contact img{
  width: 34px;
  height: 34px;
  margin-right: 18px;
  cursor: pointer;
}
.bottom_menu{
  display: flex;
  flex-direction: column;
  margin-left: 120px;
}
.bottom_menu div{
  font-size: 28px;
font-weight: 500;
color: #FFFFFF;
padding-top: 8px;
}
.bottom_menu span{
  font-size: 20px;
font-weight: 400;
color: #FFFFFF;
padding-top: 24px;
cursor: pointer;
}
</style>
