<template>
  <div class="">
      <!-- <iframe id=“frame”  src="https://106280.kefu.easemob.com/webim/im.html?configId=1da960b5-5c25-45d4-ba65-1eb2d72be445"  style="width: 100%;border: 0px;margin:0;height:100vh"></iframe> -->
  </div>
</template>

<script>

export default {
  name: 'Home',

  data() {
    return {
    };
  },
  mounted() {
   
  },
  methods: {

   },
}
</script>
<style scoped>

</style>
