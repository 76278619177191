<template>
  <div class="">
    <header-com :index="0"></header-com>
    <div class="index_container">
       <div class="index_banner">
          <div class="banner_text">
             <span>{{ $t('index.title1') }}</span>
             <span>2032MALL</span>
             <span>跨境电商平台</span>
             <span>Cross-border e-comme</span>
          </div>
           <img src="@/assets/pic.png" class="banner_pic"/>
       </div>




       <div class="index_margin_box">
           <div class="index_bg_box align-center">
              <div class="name">{{ $t('index.title2') }}</div>
               <div class="sub_name">{{ $t('index.title3') }}</div>
               <img src="@/assets/pic_2.png"/>
           </div>

                            <div class="service_container">
               <div class="title">全球云仓一体化服务</div>
               <div class="sub_title">Global Cloud Warehouse Integrated Service</div>
             <div class="swiper-container_main">
                  <div class="swiper-container swiper-container-pic">
                     <div class="swiper-wrapper">
                        <div class="swiper-slide">
                              <img src="@/assets/s_1.png" class="more_swiper_img"/>
                        </div>
                        <div class="swiper-slide">
                              <img src="@/assets/s_2.png" class="more_swiper_img"/>
                        </div>
                        <div class="swiper-slide">
                              <img src="@/assets/s_3.png" class="more_swiper_img"/>
                        </div>
                        <div class="swiper-slide">
                              <img src="@/assets/s_4.png" class="more_swiper_img"/>
                        </div>
                        <div class="swiper-slide">
                              <img src="@/assets/s_5.png" class="more_swiper_img"/>
                        </div>
                     </div>
                  </div>
                  <!-- Add Pagination -->
                     <div class="swiper-button-prev main_pre"></div><!--左箭头。如果放置在swiper外面，需要自定义样式。-->
                     <div class="swiper-button-next main_next"></div><!--右箭头。如果放置在swiper外面，需要自定义样式。-->
                </div>

                 <div class="swiper-container_main">
                  <div class="swiper-container swiper-container-text">
                     <div class="swiper-wrapper">
                        <div class="swiper-slide" v-for="(item, index) in dataText" :key="index">
                            <div class="swiper_name">{{item.name}}</div>
                             <div class="swiper_sub_name">{{item.subName}}</div>
                        </div>
                     </div>
                  </div>
                </div>

         </div>


           <div class="index_sub_banner flex">
                <div class="sub_banner_text_box">
                   <div class="name">{{ $t('index.title4') }}<br/>2032MALL</div>
                   <div class="sub_name">
                   {{ $t('index.title5') }}<br/>
                   {{ $t('index.title6') }}
                   </div>
                   <div class="center btn">
                       <span> {{ $t('index.title7') }}</span>
                       <img src="@/assets/arrow_2.png"/>
                   </div>
                </div>
                 <img src="@/assets/pic_3.png" class="sub_banner"/>
           </div>

  


          <div class="index_app_container align-center">
             <div class="app_text_left">
                <div class="name">{{ $t('index.title8') }}</div>
                <div class="sub_name">{{ $t('index.title9') }}</div>
                <div class="flex app_btn_box">
                  <div class="app_btn center" @mouseenter="showIos=true" @mouseleave="showIos=false">
                     <img src="@/assets/app_1.png"/>
                     <span>iOS版</span>
                     <div class="app_code_box center" v-show="showIos">
                         <img src="@/assets/code.png" class="img"/>
                          <div class="text">
                            {{ $t('code.title') }}<br/>{{ $t('code.subTitle') }}
                          </div>
                     </div>
                  </div>
                  <div class="app_btn center" @mouseenter="showAz=true" @mouseleave="showAz=false">
                     <img src="@/assets/app_2.png"/>
                     <span>安卓版</span>
                     <div class="app_code_box center" v-show="showAz">
                         <img src="@/assets/code.png" class="img"/>
                          <div class="text">
                            {{ $t('code.title') }}<br/>{{ $t('code.subTitle') }}
                          </div>
                     </div>
                  </div>
                </div>
             </div>
              <img src="@/assets/pic_4.png" class="app_show_pic"/>
          </div>
          <div class="third_banner_box flex">
               <img src="@/assets/pic_5.png" class="third_banner"/>
               <div class="third_banner_box_box">
                   <div class="name"> {{ $t('index.title10') }}<br/> {{ $t('index.title11') }}</div>
                   <div class="sub_name">
                     {{ $t('index.title12') }}<br/>
                    {{ $t('index.title13') }}
                   </div>
                   <div class="center btn">
                       <span>{{ $t('index.title7') }}</span>
                       <img src="@/assets/arrow_3.png"/>
                   </div>
                </div>
          </div>
       </div>
    </div>
    <bottom-com></bottom-com>
  </div>
</template>

<script>
import '@/css/index.css'
// @ is an alias to /src
import headerCom from '@/components/header.vue'
import bottomCom from '@/components/bottom.vue'
import Swiper from "swiper"

export default {
  name: 'Home',
  components: {
    headerCom,
    bottomCom
  },
  data () {
        return {
         showIos: false,
         showAz: false,
         dataText: []
        }
    } ,
   mounted(){
      this.myswiper()    
      if (localStorage.getItem('languageStorage')=='zh'){
         this.dataText = [
            {
               name:'物流服务',
               subName:'物流服务'
            },
            {
               name:'全球采购',
               subName:'全球采购'
            },
            {
               name:'检测服务',
               subName:'检测服务'
            },
            {
               name:'售后服务',
               subName:'售后服务'
            },
            {
               name:'追踪服务',
               subName:'追踪服务'
            }
         ]
      }else{
           this.dataText = [
            {
               name:'Logistics services',
               subName:'Logistics services'
            },
            {
               name:'Global Sourcing',
               subName:'Global Sourcing'
            },
            {
               name:'Detection service',
               subName:'Detection service'
            },
            {
               name:'after-sale service',
               subName:'after-sale service'
            },
            {
               name:'Tracking service',
               subName:'Tracking service'
            }
         ]
      }
  },
  methods: {
     myswiper: function() {
				var swiper = new Swiper('.swiper-container-pic', {
               loop : true,
               slidesPerView : 5,
               sapceBetween:80,
               centeredSlides:true,
               navigation: {
                  nextEl: '.main_pre',
                  prevEl: '.main_next',
               },
             });

             	var swiper2 = new Swiper('.swiper-container-text', {
               loop : true,
               centeredSlides:true,
               navigation: {
                  nextEl: '.main_pre',
                  prevEl: '.main_next',
               },
             });
  }
}
}
</script>

<style scoped>
   @import url("../../node_modules/swiper/dist/css/swiper.css");
.swiper-container-pic>.swiper-wrapper>.swiper-slide-active,.swiper-slide-duplicate-active{
      transform: scale(1.4) !important;
	}

</style>